import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C20.9942 7.03186 16.9681 3.00583 12 3ZM12 4.5C16.1421 4.5 19.5 7.85786 19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5ZM15.36 8.29L15.71 8.64C15.9018 8.83781 15.9018 9.15219 15.71 9.35L13.06 12L15.71 14.65C15.9018 14.8478 15.9018 15.1622 15.71 15.36L15.36 15.71C15.2661 15.8047 15.1383 15.8579 15.005 15.8579C14.8717 15.8579 14.7439 15.8047 14.65 15.71L12 13.06L9.35 15.71C9.25612 15.8047 9.12832 15.8579 8.995 15.8579C8.86168 15.8579 8.73388 15.8047 8.64 15.71L8.29 15.36C8.19534 15.2661 8.1421 15.1383 8.1421 15.005C8.1421 14.8717 8.19534 14.7439 8.29 14.65L10.94 12L8.29 9.35C8.19534 9.25612 8.1421 9.12832 8.1421 8.995C8.1421 8.86168 8.19534 8.73388 8.29 8.64L8.64 8.29C8.73388 8.19534 8.86168 8.1421 8.995 8.1421C9.12832 8.1421 9.25612 8.19534 9.35 8.29L12 10.94L14.65 8.29C14.7439 8.19534 14.8717 8.1421 15.005 8.1421C15.1383 8.1421 15.2661 8.19534 15.36 8.29Z"
      fill="#000F1E"
      fill-opacity="0.6"
    />
  </svg>
);
export default SvgComponent;
