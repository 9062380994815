import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.5 6a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-1ZM13 6.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-2ZM15.5 6a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-1ZM7 11.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2ZM12.5 11a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1ZM15 11.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 19.5h1.5a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5h-19a.5.5 0 0 1-.5-.5V20a.5.5 0 0 1 .5-.5H4v-16a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v16Zm-14.5 0H10v-3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3h4.5v-15h-13v15Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
