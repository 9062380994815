import { IWorkingLocation, IAttendanceRequestType } from "@gettactic/api";

export function getLocationIdentifier(
  wl: IWorkingLocation | IAttendanceRequestType | null
) {
  if (!wl) {
    return "";
  }
  if (!("location" in wl)) {
    // this is an attendance request
    return wl.id;
  }
  if (wl.location === "office" && wl.office) {
    return wl.office.id;
  }
  return wl.location ?? "";
}
