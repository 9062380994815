import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.6209 21.13C17.1209 21.13 16.1708 20.29 16.1208 18.9H17.5108C17.5409 19.58 17.9408 19.98 18.5908 19.98C19.2108 19.98 19.6009 19.67 19.6009 19.19C19.6009 18.79 19.4009 18.58 18.7608 18.34L17.9708 18.04C16.8508 17.62 16.3408 16.99 16.3408 16.04C16.3408 14.91 17.2708 14.15 18.6308 14.15C19.9708 14.15 20.8108 14.89 20.9008 16.17H19.5309C19.5009 15.61 19.1808 15.29 18.6308 15.29C18.0908 15.29 17.7408 15.57 17.7408 15.99C17.7408 16.41 17.9708 16.66 18.5708 16.89L19.4108 17.21C20.5708 17.65 21.0209 18.18 21.0209 19.1C21.0209 20.35 20.0909 21.13 18.6209 21.13Z"
      fill="currentColor"
    />
    <path
      d="M11.5554 21V15.56H9.76538V14.27H14.8654V15.56H12.9854V21H11.5554Z"
      fill="currentColor"
    />
    <path
      d="M3.43256 21V14.27H6.11256C7.50256 14.27 8.33256 14.93 8.33256 16.03C8.33256 16.69 8.09256 17.18 7.64256 17.48C8.29256 17.77 8.64256 18.33 8.64256 19.14C8.64256 20.29 7.76256 21 6.36256 21H3.43256ZM6.24256 19.82C6.90256 19.82 7.27256 19.5 7.27256 18.94C7.27256 18.4 6.88256 18.09 6.21256 18.09H4.78256V19.82H6.24256ZM6.07256 16.97C6.65256 16.97 6.98256 16.7 6.98256 16.21C6.98256 15.71 6.68256 15.45 6.09256 15.45H4.78256V16.97H6.07256Z"
      fill="currentColor"
    />
    <path
      d="M19.5 11C19.7761 11 20 10.7761 20 10.5V3.5C20 3.22386 19.7761 3 19.5 3H4.5C4.22386 3 4 3.22386 4 3.5V10.5C4 10.7761 4.22386 11 4.5 11H5C5.27614 11 5.5 10.7761 5.5 10.5V4.5H18.5V10.5C18.5 10.7761 18.7239 11 19 11H19.5Z"
      fill="currentColor"
    />
    <path
      d="M7 7.5C7 7.22386 7.22386 7 7.5 7H8.5C8.77614 7 9 7.22386 9 7.5V9.5C9 9.77614 8.77614 10 8.5 10H7.5C7.22386 10 7 9.77614 7 9.5V7.5Z"
      fill="currentColor"
    />
    <path
      d="M13 7.5C13 7.22386 12.7761 7 12.5 7H11.5C11.2239 7 11 7.22386 11 7.5V9.5C11 9.77614 11.2239 10 11.5 10H12.5C12.7761 10 13 9.77614 13 9.5V7.5Z"
      fill="currentColor"
    />
    <path
      d="M15 7.5C15 7.22386 15.2239 7 15.5 7H16.5C16.7761 7 17 7.22386 17 7.5V9.5C17 9.77614 16.7761 10 16.5 10H15.5C15.2239 10 15 9.77614 15 9.5V7.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComponent;
