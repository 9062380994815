import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.8738 21.13C16.3738 21.13 15.4238 20.29 15.3738 18.9H16.7638C16.7938 19.58 17.1938 19.98 17.8438 19.98C18.4638 19.98 18.8538 19.67 18.8538 19.19C18.8538 18.79 18.6538 18.58 18.0138 18.34L17.2238 18.04C16.1038 17.62 15.5938 16.99 15.5938 16.04C15.5938 14.91 16.5238 14.15 17.8838 14.15C19.2238 14.15 20.0638 14.89 20.1538 16.17H18.7838C18.7538 15.61 18.4338 15.29 17.8838 15.29C17.3438 15.29 16.9938 15.57 16.9938 15.99C16.9938 16.41 17.2238 16.66 17.8238 16.89L18.6638 17.21C19.8238 17.65 20.2738 18.18 20.2738 19.1C20.2738 20.35 19.3438 21.13 17.8738 21.13Z"
      fill="currentColor"
    />
    <path
      d="M10.2605 21V14.27H11.6905V19.71H14.1405V21H10.2605Z"
      fill="currentColor"
    />
    <path
      d="M4.17963 21V14.27H8.24963V15.49H5.60963V16.96H8.14963V18.12H5.60963V19.78H8.35963V21H4.17963Z"
      fill="currentColor"
    />
    <path
      d="M5.5 7.62132L7.62132 5.5H16.3787L18.5 7.62132V10.5C18.5 10.7761 18.7239 11 19 11H19.5C19.7761 11 20 10.7761 20 10.5V7.20711C20 7.0745 19.9473 6.94732 19.8536 6.85355L17.1464 4.14645C17.0527 4.05268 16.9255 4 16.7929 4H7.20711C7.0745 4 6.94732 4.05268 6.85355 4.14645L4.14645 6.85355C4.05268 6.94732 4 7.0745 4 7.20711V10.5C4 10.7761 4.22386 11 4.5 11H5C5.27614 11 5.5 10.7761 5.5 10.5V7.62132Z"
      fill="currentColor"
    />
    <path
      d="M7 9C7 8.44772 7.44772 8 8 8C8.55228 8 9 8.44772 9 9V11H7V9Z"
      fill="currentColor"
    />
    <path
      d="M11 9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V11H11V9Z"
      fill="currentColor"
    />
    <path
      d="M15 9C15 8.44772 15.4477 8 16 8C16.5523 8 17 8.44772 17 9V11H15V9Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComponent;
