import { getPolicyIcon } from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';
import { CalendarXMark } from '@/components/basic/icons/CalendarXMark';
import { RedbullOfficeIcon } from '@/components/basic/icons/RedbullOfficeIcon';
import { useAuthenticated } from '@/lib/api/appUser';
import { isRBFeature } from '@/lib/featureFlags';
import {
  Division,
  IWorkingLocation,
  WL_NOT_AVAILABLE,
  WL_PRESENCE_OOO,
  WL_PRESENCE_REMOTE
} from '@gettactic/api';
import { useDivisionsMenuQuery } from '@gettactic/hooks';
import {
  HomeIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/outline';
import { HiNoSymbol } from 'react-icons/hi2';

type Props = {
  workingLocationId: string | null;
  fullLocation?: IWorkingLocation | null;
  className?: string;
};

export function WorkingLocationPresenceIcon({
  workingLocationId,
  fullLocation,
  className = 'w-5 h-5'
}: Props) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const isRBFeatureEnabled = isRBFeature(authenticatedUser);
  const divisions = useDivisionsMenuQuery();
  const divisionsByOfficeId =
    divisions.data?.byOfficeId ?? new Map<string, Division>();

  if (
    fullLocation &&
    fullLocation.location === WL_PRESENCE_OOO &&
    fullLocation.policy_type === 'remote'
  ) {
    if (isRBFeatureEnabled) {
      return <RedbullOfficeIcon region={'home'} className={className} />;
    }
    return <HomeIcon className={className} />;
  }
  if (
    fullLocation &&
    fullLocation.location === WL_PRESENCE_OOO &&
    fullLocation.policy_type
  ) {
    const Icon = getPolicyIcon(fullLocation.policy_type);
    return <Icon className={className} />;
  }
  if (workingLocationId === WL_PRESENCE_REMOTE) {
    if (isRBFeatureEnabled) {
      return <RedbullOfficeIcon region={'home'} className={className} />;
    }
    return <HomeIcon className={className} />;
  }
  if (workingLocationId === '') {
    if (isRBFeatureEnabled) {
      return <RedbullOfficeIcon region={'no'} className={className} />;
    }
    return <QuestionMarkCircleIcon className={className} />;
  }
  if (workingLocationId === WL_PRESENCE_OOO) {
    if (isRBFeatureEnabled) {
      return <RedbullOfficeIcon region={'ooo'} className={className} />;
    }
    return <CalendarXMark className={className} />;
  }
  if (workingLocationId === WL_NOT_AVAILABLE) {
    if (isRBFeatureEnabled) {
      return <RedbullOfficeIcon region={'no'} className={className} />;
    }
    return <HiNoSymbol className={className} />;
  }

  if (isRBFeatureEnabled) {
    if (
      fullLocation?.office &&
      divisionsByOfficeId.has(fullLocation?.office.id)
    ) {
      return (
        <RedbullOfficeIcon
          region={divisionsByOfficeId.get(fullLocation?.office.id)?.name}
          className={className}
        />
      );
    }
    if (divisionsByOfficeId.has(workingLocationId as string)) {
      return (
        <RedbullOfficeIcon
          region={divisionsByOfficeId.get(workingLocationId as string)?.name}
          className={className}
        />
      );
    }
  }
  return <OfficeBuildingIcon className={className} />;
}
