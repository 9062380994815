import React, { forwardRef, ForwardRefRenderFunction, useContext, useImperativeHandle, useState } from 'react';
import { Input } from '@mantine/core';
import { Search, CircleX } from 'lucide-react';
import { useLocationFilterContext } from './LocationInputContext';

export type FilterInputHandle = {
  resetFilter: () => void;
}

const FilterInputComponent: ForwardRefRenderFunction<FilterInputHandle, {}> = ({} , ref) => {
  const {locationFilter, setLocationFilter, resetLocationFilter} = useLocationFilterContext();

  useImperativeHandle(ref, () => ({
    resetFilter: resetLocationFilter,
  }));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setLocationFilter(value);
  };

  return (
    <Input
      placeholder="Search"
      value={locationFilter}
      onChange={handleChange}
      icon={<Search className="h-4 w-4 text-gray-500" />}
      rightSection={
        locationFilter && (
          <CircleX
            onClick={resetLocationFilter}
            className="h-4 w-4 text-gray-700"
          />
        )
      }
      styles={{
        input: { paddingLeft: '2rem' },
      }}
    />
  );
};

export const FilterInput = forwardRef(FilterInputComponent);
