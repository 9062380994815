import React from 'react';

export const LabelWithHighlight = ({ label, highlight }: {
    label: string;
    highlight: string;
}) => {
    if (!highlight || highlight === '') {
        return <span>{label}</span>;
    }
    const parts = label.split(new RegExp(`(${highlight})`, 'gi'));
  
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className='bg-highlight/30'>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };