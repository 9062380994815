import { DivisionsTree } from "@gettactic/api";
import {
  CheckIcon,
  FolderIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline";
import { Collapse, Menu } from "@mantine/core";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import React from "react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { LabelWithHighlight } from "../../basic/LabelWithHighlight/LabelWithHighlight";

import { useLocationFilterContext } from "./LocationInputContext";

function hasFilterInTreeRecursive(tree: DivisionsTree, filter: string) {
  if (!filter) {
    return true;
  }

  // if division name has a filter
  if (tree.name?.toLowerCase().includes(filter.toLowerCase())) {
    return true;
  }

  // if any of offices have a filter in a name
  if (
    tree.offices?.some((office) =>
      office.name.toLowerCase().includes(filter.toLowerCase())
    )
  ) {
    return true;
  }

  for (const child of tree.children) {
    if (hasFilterInTreeRecursive(child, filter)) {
      return true;
    }
  }

  return false;
}

const OFFICES_LABEL = "Offices";
const TOP_LEVEL = 1;

export function RenderDivisionsMenu({
  tree,
  level = 0,
  setValue,
  value,
}: {
  tree: DivisionsTree;
  level?: number;
  setValue: (x: string) => void;
  value: string;
  }) {
  const { locationFilter } = useLocationFilterContext();
  
  const treeName =  useMemo(() => tree.name || OFFICES_LABEL, [tree.name]);
  const isRootBranch = useMemo(() => level === TOP_LEVEL, [level]);
  
  const [openDivisions, setOpenDivisionsDivisions] =
    useState<boolean>(!!locationFilter || isRootBranch);

  useEffect(() => {
    if (locationFilter) {
      setOpenDivisionsDivisions(true);
    }
  }, [locationFilter]);

  const style = { paddingLeft: `${level * 0.2}rem` };

  const hideNode = useMemo(() => {
    return hasFilterInTreeRecursive(tree, locationFilter) === false;
  }, [locationFilter]);

  if (hideNode) {
    return null;
  }

  return (
    <>
      <Menu.Item
        style={style}
        rightSection={
          Object.keys(tree.children).length > 0 || tree.offices.length > 0 ? (
            openDivisions ? (
              <IconChevronDown size={14} />
            ) : (
              <IconChevronRight size={14} />
            )
          ) : null
        }
        icon={<FolderIcon className="h-5 w-5 text-black" />}
        closeMenuOnClick={false}
        onClick={() => {
          setOpenDivisionsDivisions((p) => !p);
        }}
      >
        <LabelWithHighlight
          label={treeName}
          highlight={locationFilter}
        />
      </Menu.Item>
      <Collapse style={style} in={openDivisions}>
        {tree.children.map((child) => (
          <Fragment key={`key_${child.id}`}>
            <RenderDivisionsMenu
              key={child.id}
              tree={child}
              level={level + 1}
              setValue={setValue}
              value={value}
            />
          </Fragment>
        ))}
        {tree.offices.map((office) => (
          <Menu.Item
            key={`offi_${office.id}`}
            icon={<OfficeBuildingIcon className="h-5 w-5" />}
            onClick={() => {
              setValue(office.id);
            }}
            rightSection={
              office.id === value ? (
                <CheckIcon className="ml-1 h-5 w-5 text-primary" />
              ) : null
            }
          >
            <LabelWithHighlight label={office.name} highlight={locationFilter} />
          </Menu.Item>
        ))}
      </Collapse>
    </>
  );
}
