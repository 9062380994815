import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4902 21V14.27H17.9202V19.71H20.3702V21H16.4902Z"
      fill="#1B6AEE"
    />
    <path
      d="M12.1835 21.13C10.6835 21.13 9.73353 20.29 9.68353 18.9H11.0735C11.1035 19.58 11.5035 19.98 12.1535 19.98C12.7735 19.98 13.1635 19.67 13.1635 19.19C13.1635 18.79 12.9635 18.58 12.3235 18.34L11.5335 18.04C10.4135 17.62 9.90353 16.99 9.90353 16.04C9.90353 14.91 10.8335 14.15 12.1935 14.15C13.5335 14.15 14.3735 14.89 14.4635 16.17H13.0935C13.0635 15.61 12.7435 15.29 12.1935 15.29C11.6535 15.29 11.3035 15.57 11.3035 15.99C11.3035 16.41 11.5335 16.66 12.1335 16.89L12.9735 17.21C14.1335 17.65 14.5835 18.18 14.5835 19.1C14.5835 20.35 13.6535 21.13 12.1835 21.13Z"
      fill="#1B6AEE"
    />
    <path
      d="M4.20657 21V14.27H8.24657V15.56H5.63657V17.15H8.15657V18.39H5.63657V21H4.20657Z"
      fill="#1B6AEE"
    />
    <path
      d="M20.0555 11L13.3972 4.15146C13.3031 4.05463 13.1738 4 13.0387 4H10.9612C10.8262 4 10.6969 4.05463 10.6027 4.15146L3.94443 11H5.82525C5.9603 11 6.08961 10.9454 6.18375 10.8485L11.2365 5.65146C11.3306 5.55463 11.4599 5.5 11.595 5.5H12.405C12.5401 5.5 12.6694 5.55463 12.7635 5.65146L17.8162 10.8485C17.9104 10.9454 18.0397 11 18.1747 11H20.0555Z"
      fill="#1B6AEE"
    />
    <rect x="9" y="9.5" width="6" height="1.5" rx="0.5" fill="#1B6AEE" />
  </svg>
);
export default SvgComponent;
