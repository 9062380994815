import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.671 10.767L12.333 4.12898C12.1444 3.95684 11.8556 3.95684 11.667 4.12898L4.329 10.767C4.11963 10.9565 4.00013 11.2256 4 11.508V19.494C4.0011 19.773 4.227 19.9989 4.506 20H10C10.2761 20 10.5 19.7761 10.5 19.5V14.5H13.5V19.5C13.5 19.7761 13.7239 20 14 20H19.5C19.7761 20 20 19.7761 20 19.5V11.508C19.9999 11.2256 19.8804 10.9565 19.671 10.767ZM18.55 18.55H15V13.5C15 13.2238 14.7761 13 14.5 13H9.5C9.22386 13 9 13.2238 9 13.5V18.55H5.45V11.708L12 5.78198L18.55 11.708V18.55Z"
      fill="#000F1E"
      fill-opacity="0.6"
    />
  </svg>
);
export default SvgComponent;
