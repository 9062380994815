import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7491 21.13C18.2491 21.13 17.2991 20.29 17.2491 18.9H18.6391C18.6691 19.58 19.0691 19.98 19.7191 19.98C20.3391 19.98 20.7291 19.67 20.7291 19.19C20.7291 18.79 20.5291 18.58 19.8891 18.34L19.0991 18.04C17.9791 17.62 17.4691 16.99 17.4691 16.04C17.4691 14.91 18.3991 14.15 19.7591 14.15C21.0991 14.15 21.9391 14.89 22.0291 16.17H20.6591C20.6291 15.61 20.3091 15.29 19.7591 15.29C19.2191 15.29 18.8691 15.57 18.8691 15.99C18.8691 16.41 19.0991 16.66 19.6991 16.89L20.5391 17.21C21.6991 17.65 22.1491 18.18 22.1491 19.1C22.1491 20.35 21.2191 21.13 19.7491 21.13Z"
      fill="currentColor"
    />
    <path
      d="M12.5358 21V14.27H13.9658V19.71H16.4158V21H12.5358Z"
      fill="currentColor"
    />
    <path
      d="M3.77422 21L1.88422 14.27H3.42422L4.62422 19.05L5.73422 14.27H7.23422L8.35422 19.04L9.55422 14.27H11.0942L9.21422 21H7.65422L6.49422 16.2L5.31422 21H3.77422Z"
      fill="currentColor"
    />
    <path
      d="M13.5 11C13.7761 11 14 10.7761 14 10.5V3.5C14 3.22386 13.7761 3 13.5 3H3.5C3.22386 3 3 3.22386 3 3.5V10.5C3 10.7761 3.22386 11 3.5 11H4C4.27614 11 4.5 10.7761 4.5 10.5V4.5H12.5V10.5C12.5 10.7761 12.7239 11 13 11H13.5Z"
      fill="currentColor"
    />
    <path
      d="M20.5 11C20.7761 11 21 10.7761 21 10.5V5.5C21 5.22386 20.7761 5 20.5 5H15.5C15.2239 5 15 5.22386 15 5.5V10.5C15 10.7761 15.2239 11 15.5 11H16C16.2761 11 16.5 10.7761 16.5 10.5V6.5H19.5V10.5C19.5 10.7761 19.7239 11 20 11H20.5Z"
      fill="currentColor"
    />
    <path
      d="M6 6.5C6 6.22386 6.22386 6 6.5 6H7.5C7.77614 6 8 6.22386 8 6.5V8.5C8 8.77614 7.77614 9 7.5 9H6.5C6.22386 9 6 8.77614 6 8.5V6.5Z"
      fill="currentColor"
    />
    <path
      d="M18 7.5C18 7.22386 17.7761 7 17.5 7V7C17.2239 7 17 7.22386 17 7.5V8.5C17 8.77614 17.2239 9 17.5 9V9C17.7761 9 18 8.77614 18 8.5V7.5Z"
      fill="currentColor"
    />
    <path
      d="M10 6.5C10 6.22386 10.2239 6 10.5 6V6C10.7761 6 11 6.22386 11 6.5V8.5C11 8.77614 10.7761 9 10.5 9V9C10.2239 9 10 8.77614 10 8.5V6.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComponent;
