import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C20.9942 7.03186 16.9681 3.00583 12 3ZM12 4.5C16.1421 4.5 19.5 7.85786 19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5ZM10.2699 8.55003H12.2699C12.8643 8.45903 13.4681 8.64029 13.9141 9.04362C14.36 9.44694 14.6009 10.0295 14.5699 10.63C14.5699 11.44 14.3199 11.9 12.9899 12.9C12.8985 12.9628 12.7908 12.9976 12.6799 13H11.5199C11.3818 13 11.2699 12.8881 11.2699 12.75V12.59C11.2715 12.5145 11.3085 12.4441 11.3699 12.4C11.5212 12.2753 11.6582 12.1632 11.7821 12.0619L11.7821 12.0619C12.6813 11.3259 12.8899 11.1552 12.8899 10.83C12.9075 10.5941 12.8171 10.3629 12.6441 10.2015C12.471 10.0401 12.2341 9.966 11.9999 10H10.2699C10.1895 10.0029 10.1116 9.97215 10.0547 9.91527C9.99778 9.85838 9.96708 9.78042 9.96991 9.70003V8.85003C9.96708 8.76963 9.99778 8.69167 10.0547 8.63479C10.1116 8.5779 10.1895 8.5472 10.2699 8.55003ZM12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"
      fill="#000F1E"
      fill-opacity="0.4"
    />
  </svg>
);
export default SvgComponent;
