import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.1871 21V14.27H19.6171V21H18.1871Z" fill="currentColor" />
    <path
      d="M11.3347 21V14.27H13.6747C15.1347 14.27 16.0447 15.09 16.0447 16.42C16.0447 17.33 15.6147 18.01 14.8647 18.34L16.5147 21H14.8547L13.4047 18.56H12.7647V21H11.3347ZM13.6347 17.41C14.2447 17.41 14.6347 17.04 14.6347 16.43C14.6347 15.83 14.2647 15.46 13.6347 15.46H12.7547V17.41H13.6347Z"
      fill="currentColor"
    />
    <path
      d="M8.18471 21L5.8147 17.88V21H4.3847V14.27H5.8147V17.1L8.0647 14.27H9.70471L7.1647 17.45L9.8847 21H8.18471Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5 11C14.2239 11 14 10.7761 14 10.5V8H12V10.5C12 10.7761 11.7761 11 11.5 11H11C10.7239 11 10.5 10.7761 10.5 10.5V4.5H5.5V10.5C5.5 10.7761 5.27614 11 5 11H4.5C4.22386 11 4 10.7761 4 10.5V3.5C4 3.22386 4.22386 3 4.5 3H11.5C11.7761 3 12 3.22386 12 3.5V4H14V3.5C14 3.22386 14.2239 3 14.5 3H18.5C18.7761 3 19 3.22386 19 3.5V5H19.5C19.7761 5 20 5.22386 20 5.5V5.5C20 5.77614 19.7761 6 19.5 6H19V8H19.5C19.7761 8 20 8.22386 20 8.5V8.5C20 8.77614 19.7761 9 19.5 9H19V10.5C19 10.7761 18.7761 11 18.5 11H18C17.7239 11 17.5 10.7761 17.5 10.5V4.5H15.5V10.5C15.5 10.7761 15.2761 11 15 11H14.5ZM14 7H12V5H14V7Z"
      fill="currentColor"
    />
    <path
      d="M7 6.5C7 6.22386 7.22386 6 7.5 6H8.5C8.77614 6 9 6.22386 9 6.5V6.5C9 6.77614 8.77614 7 8.5 7H7.5C7.22386 7 7 6.77614 7 6.5V6.5Z"
      fill="currentColor"
    />
    <path
      d="M9 9.5C9 9.22386 8.77614 9 8.5 9H7.5C7.22386 9 7 9.22386 7 9.5V9.5C7 9.77614 7.22386 10 7.5 10H8.5C8.77614 10 9 9.77614 9 9.5V9.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComponent;
