import * as React from 'react';
const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 6C7.22386 6 7 6.22386 7 6.5V8.5C7 8.77614 7.22386 9 7.5 9H8.5C8.77614 9 9 8.77614 9 8.5V6.5C9 6.22386 8.77614 6 8.5 6H7.5Z"
      fill="currentColor"
    />
    <path
      d="M13 6.5C13 6.22386 12.7761 6 12.5 6H11.5C11.2239 6 11 6.22386 11 6.5V8.5C11 8.77614 11.2239 9 11.5 9H12.5C12.7761 9 13 8.77614 13 8.5V6.5Z"
      fill="currentColor"
    />
    <path
      d="M15.5 6C15.2239 6 15 6.22386 15 6.5V8.5C15 8.77614 15.2239 9 15.5 9H16.5C16.7761 9 17 8.77614 17 8.5V6.5C17 6.22386 16.7761 6 16.5 6H15.5Z"
      fill="currentColor"
    />
    <path
      d="M7 11.5C7 11.2239 7.22386 11 7.5 11H8.5C8.77614 11 9 11.2239 9 11.5V13.5C9 13.7761 8.77614 14 8.5 14H7.5C7.22386 14 7 13.7761 7 13.5V11.5Z"
      fill="currentColor"
    />
    <path
      d="M12.5 11C12.7761 11 13 11.2239 13 11.5V13.5C13 13.7761 12.7761 14 12.5 14H11.5C11.2239 14 11 13.7761 11 13.5V11.5C11 11.2239 11.2239 11 11.5 11H12.5Z"
      fill="currentColor"
    />
    <path
      d="M15 11.5C15 11.2239 15.2239 11 15.5 11H16.5C16.7761 11 17 11.2239 17 11.5V13.5C17 13.7761 16.7761 14 16.5 14H15.5C15.2239 14 15 13.7761 15 13.5V11.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 19.5H21.5C21.7761 19.5 22 19.7239 22 20V20.5C22 20.7761 21.7761 21 21.5 21H2.5C2.22386 21 2 20.7761 2 20.5V20C2 19.7239 2.22386 19.5 2.5 19.5H4V3.5C4 3.22386 4.22386 3 4.5 3H19.5C19.7761 3 20 3.22386 20 3.5V19.5ZM5.5 19.5H10V16.5C10 16.2239 10.2239 16 10.5 16H13.5C13.7761 16 14 16.2239 14 16.5V19.5H18.5V4.5H5.5V19.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;
