import React, { createContext, useState, ReactNode, useContext } from 'react';

interface LocationFilterContextValue {
  locationFilter: string;
  setLocationFilter: (filter: string) => void;
  resetLocationFilter: () => void;
}

export const LocationFilterContext = createContext<LocationFilterContextValue>({
  locationFilter: '',
  setLocationFilter: () => {},
  resetLocationFilter: () => {},
});

interface LocationFilterProviderProps {
  children: ReactNode;
}

export const LocationFilterProvider: React.FC<LocationFilterProviderProps> = ({
  children,
}) => {
  const [locationFilter, setLocationFilter] = useState<string>('');

  const resetLocationFilter = () => {
    setLocationFilter('');
  };

  return (
    <LocationFilterContext.Provider
      value={{
        locationFilter,
        setLocationFilter,
        resetLocationFilter,
      }}
    >
      {children}
    </LocationFilterContext.Provider>
  );
};

export const useLocationFilterContext = () => {
  const context = useContext(LocationFilterContext);
  if (!context) {
    throw new Error(
      'useLocationFilterContext must be used within a LocationFilterProvider'
    );
  }
  return context;
};