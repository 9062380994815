import { classNames } from '@/lib/classNames';
import RedbullAucklandIcon from '@gettactic/components/src/icons/RedBull/RedbullAucklandIcon';
import RedbullBratislavaIcon from '@gettactic/components/src/icons/RedBull/RedbullBratislavaIcon';
import RedbullBudapestIcon from '@gettactic/components/src/icons/RedBull/RedbullBudapestIcon';
import RedbullElsbethenIcon from '@gettactic/components/src/icons/RedBull/RedbullElsbethenIcon';
import RedbullFuschlIcon from '@gettactic/components/src/icons/RedBull/RedbullFuschlIcon';
import RedbullGenericIcon from '@gettactic/components/src/icons/RedBull/RedbullGenericIcon';
import RedbullHomeIcon from '@gettactic/components/src/icons/RedBull/RedbullHomeIcon';
import RedbullKrieauIcon from '@gettactic/components/src/icons/RedBull/RedbullKrieauIcon';
import RedbullMunichIcon from '@gettactic/components/src/icons/RedBull/RedbullMunichIcon';
import RedbullNoIcon from '@gettactic/components/src/icons/RedBull/RedbullNoIcon';
import RedbullViennaIcon from '@gettactic/components/src/icons/RedBull/RedbullViennaIcon';
import RedbullWalsIcon from '@gettactic/components/src/icons/RedBull/RedbullWalsIcon';
import RedbulloooIcon from '@gettactic/components/src/icons/RedBull/RedbulloooIcon';

const RedbullRegionIcons = Object.freeze({
  Auckland: RedbullAucklandIcon,
  Bratislava: RedbullBratislavaIcon,
  Budapest: RedbullBudapestIcon,
  Elsbethen: RedbullElsbethenIcon,
  Fuschl: RedbullFuschlIcon,
  Generic: RedbullGenericIcon,
  Krieau: RedbullKrieauIcon,
  Munich: RedbullMunichIcon,
  Vienna: RedbullViennaIcon,
  Wals: RedbullWalsIcon,
  no: RedbullNoIcon,
  ooo: RedbulloooIcon,
  home: RedbullHomeIcon
});

export type RedBullIconRegionType = keyof typeof RedbullRegionIcons;

export function RedbullOfficeIcon({
  className = '',
  region = 'Generic'
}: { className?: string; region?: RedBullIconRegionType }) {
  const Icon = RedbullRegionIcons[region] ?? RedbullRegionIcons.Generic;
  return (
    <div className={classNames('flex items-center justify-center', className)}>
      <Icon />
    </div>
  );
}
