import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { LeavePolicy, LeavePolicyType } from '@gettactic/api';
import { api } from '@/lib/api/api';

export type QueryOptions =
  | { keepPreviousData?: boolean; enabled?: boolean }
  | undefined;

export const USE_LEAVE_POLICIES_QUERY_KEY = 'leavePolicies';
export function useAllApplicablePolicies(
  params: {
    tz: string;
    start?: string;
    end?: string;
    type?: LeavePolicyType[];
  },
  options: QueryOptions = { keepPreviousData: true, enabled: true }
) {
  const queryClient = useQueryClient();

  const query = useQuery(
    [USE_LEAVE_POLICIES_QUERY_KEY, params],
    async () => {
      const res = await api.client.leave.allApplicablePolicies(params);
      return res.filter((x) => !!x && !!x.policy);
    },
    options
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries([USE_LEAVE_POLICIES_QUERY_KEY]);
  }, []);
  return { query, invalidate };
}

export type RemotePolicy = {
  policy: LeavePolicy;
  allotted: number | null;
  available: number | null;
  used: number | null;
};
export function useApplicableRemotePolicy(
  params: { tz: string; start?: string; end?: string },
  options?: QueryOptions
): RemotePolicy | null  {
  const { query } = useAllApplicablePolicies(
    { ...params, type: ['remote'] },
    options
  );
  return query.data?.find((x) => x?.policy?.policy_type === 'remote') ?? null;
}
