export function CalendarXMark({ className = '' }: { className: string }) {
  return (
    <svg
      className={className}
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63504 11.1266L10.1819 12.721C10.5234 13.0399 10.5234 13.5555 10.1819 13.8439C9.89732 14.1899 9.38839 14.1899 9.07366 13.8439L7.5 12.2766L5.89621 13.8439C5.61161 14.1899 5.10268 14.1899 4.78795 13.8439C4.47656 13.5555 4.47656 13.0399 4.78795 12.721L6.36496 11.1266L4.78795 9.50175C4.47656 9.2134 4.47656 8.69778 4.78795 8.37891C5.10268 8.06343 5.61161 8.06343 5.89621 8.37891L7.5 9.97667L9.07366 8.37891C9.38839 8.06343 9.89732 8.06343 10.1819 8.37891C10.5234 8.69778 10.5234 9.2134 10.1819 9.50175L8.63504 11.1266ZM4.28571 0C4.73103 0 5.08929 0.364669 5.08929 0.814145V2.17105H9.91071V0.814145C9.91071 0.364669 10.269 0 10.7143 0C11.1596 0 11.5179 0.364669 11.5179 0.814145V2.17105H12.8571C14.0391 2.17105 15 3.14294 15 4.34211V15.1974C15 16.3948 14.0391 17.3684 12.8571 17.3684H2.14286C0.959263 17.3684 0 16.3948 0 15.1974V4.34211C0 3.14294 0.959263 2.17105 2.14286 2.17105H3.48214V0.814145C3.48214 0.364669 3.8404 0 4.28571 0ZM13.3929 6.51316H1.60714V15.1974C1.60714 15.4959 1.84687 15.7401 2.14286 15.7401H12.8571C13.1518 15.7401 13.3929 15.4959 13.3929 15.1974V6.51316Z"
        fill="currentColor"
      />
    </svg>
  );
}
